import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Learnings and tools
// Material Design, MUI, Figma, Storybook, React, Amplitude
import { ReactComponent as MaterialdesignIcon } from './svg/materialdesign.svg';
import { ReactComponent as MUIIcon } from './svg/mui.svg';
import { ReactComponent as FigmaIcon } from './svg/figma.svg';
import { ReactComponent as StorybookIcon } from './svg/storybook.svg';
import { ReactComponent as CRAIcon } from './svg/cra.svg';
import { ReactComponent as AmplitudeIcon } from './svg/amplitude.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
// import DemoLink from '@common/DemoLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  Element,
  LearningsGrid,
  Learning,
  LearningTopic,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './multi-brand-design-system.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/multi-brand-design-system/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/multi-brand-design-system/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>

              <Paragraph>{description}</Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="The design system applied"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <MaterialdesignIcon />
                  <LearningTopic>Material Design</LearningTopic>
                </Learning>
                <Learning>
                  <MUIIcon />
                  <LearningTopic>Material-UI (MUI)</LearningTopic>
                </Learning>
                <Learning>
                  <FigmaIcon />
                  <LearningTopic>Figma</LearningTopic>
                </Learning>
                <Learning>
                  <StorybookIcon />
                  <LearningTopic>Storybook</LearningTopic>
                </Learning>
                <Learning>
                  <CRAIcon />
                  <LearningTopic>create-react-app</LearningTopic>
                </Learning>
                <Learning>
                  <AmplitudeIcon />
                  <LearningTopic>Amplitude</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Title>Understading the context</Title>
              <Paragraph>
                Public sector organizations have been under a lot of pressure to
                digitalize their services to better serve their citizens, by
                requirement of new laws and by the advance of digital services
                in all aspects of our society.
              </Paragraph>
              <Paragraph>
                Most of them have done a decent job by now with their websites,
                the entry point of their digital presence:
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="websites"
                overflow
                alt="Screenshots from the websites of several local governments"
                description="Websites of public agencies"
              />

              <Paragraph>
                But when it comes to transactional systems, the reality is that
                most of the public sector falls behind either in features, in
                design or both. Larger organizations usually rely in tailor-made
                solutions by big tech companies and consultancies or by the
                organization itself, and smaller organizations tend to rely on
                products from boutique companies or rare open-sourced systems
                from other organizations.
              </Paragraph>
              <Paragraph>
                A quick overview of commercial products from boutique companies,
                for example, reveals many issues:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="products-1"
                overflow
                alt="First block of screenshots from different existing solutions"
              />
              <Image
                edges={data.designs.edges}
                name="products-2"
                overflow
                alt="Second block of screenshots from different existing solutions"
              />
              <Image
                edges={data.designs.edges}
                name="products-3"
                overflow
                alt="Third block of screenshots from different existing solutions"
                description="Software from competitors"
              />

              <List>
                <Item>
                  The front-end stack is <b>outdated</b> with technologies like
                  JSP, ASP.Net, vanilla CSS / Javascript, jQuery
                </Item>
                <Item>
                  They have been <b>built from scratch</b>, usually without a
                  design system (with Bootstrap being the exception)
                </Item>
                <Item>
                  Heavily rely on <b>code customizations</b> like templates,
                  extensive CSS rules, custom backend or frontend development
                </Item>
                <Item>
                  Design patterns usually <b>deviate</b> from well-known and
                  established UI/UX patterns
                </Item>
                <Item>
                  There’s a clear <b>lack of customization</b> available
                </Item>
                <Item>
                  <b>Don’t feel organically integrated</b> with other systems of
                  the customer organization
                </Item>
                <Item>
                  <b>Visual design is lacking</b>, to the point that some of
                  them feel like scam sites or the web from a decade ago
                </Item>
              </List>

              <Element>
                <div
                  style={{
                    width: '100%',
                    height: 0,
                    paddingBottom: '54%',
                    position: 'relative',
                  }}
                >
                  <iframe
                    src="https://giphy.com/embed/qwZQ7BqbWiIj6"
                    width="100%"
                    height="100%"
                    title="That sucks"
                    style={{ position: 'absolute' }}
                    frameBorder="0"
                    class="giphy-embed"
                    allowFullScreen
                  ></iframe>
                </div>
              </Element>

              <Paragraph>
                The above can be condensed and rated on four dimensions:
              </Paragraph>
              <List>
                <Item>
                  <b>Customization:</b> how well the solution can be adapted to
                  look like part of the organization
                </Item>
                <Item>
                  <b>Integration:</b> what methods and to what degree the
                  solution relates to other systems of the organization
                </Item>
                <Item>
                  <b>Technology:</b> what has been used to develop the solution
                  and what is is being used to deploy it
                </Item>
                <Item>
                  <b>Wow factor:</b> how good (or bad) is the user experience
                  through their journey with the solution
                </Item>
              </List>
              <Image
                edges={data.designs.edges}
                name="competitors"
                overflow
                alt="Rating of different solutions on the four dimensions"
                description="Analysis of products from competitors"
              />

              <Title>Specifying user requirements</Title>
              <Paragraph>
                A multi-brand design system can tackle 3 of the 4 dimensions:
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="aspiration"
                overflow
                portrait
                alt="Formulation of an aspiration based on the requirements"
                description="Aspiration for the project"
              />

              <Paragraph>
                Requirements addressable for the <b>customer</b> (the public
                sector organization):
              </Paragraph>
              <List>
                <Item>Easy to adopt and deploy (technology)</Item>
                <Item>Up-to-date stack (technology)</Item>
                <Item>No-code personalization (customization)</Item>
              </List>
              <Paragraph>
                Requirements addressable for the <b>end user</b> (the citizens):
              </Paragraph>
              <List>
                <Item>Familiar patterns and flows (wow factor)</Item>
                <Item>Usable on any device (technology)</Item>
                <Item>
                  Trustable, organically integrated (wow factor + customization)
                </Item>
              </List>

              <Title>Designing the solution</Title>
              <Paragraph>
                To ensure a certain degree of familiarity and availability of an
                implementation with modern stacks, several popular design
                systems were considered:
              </Paragraph>
              <List>
                <Item>
                  <ExternalLink href="https://material.io/design">
                    Material Design
                  </ExternalLink>{' '}
                  (Google)
                </Item>
                <Item>
                  <ExternalLink href="https://developer.apple.com/design/human-interface-guidelines/">
                    Human Interface Guidelines
                  </ExternalLink>{' '}
                  (Apple)
                </Item>
                <Item>
                  <ExternalLink href="https://atlassian.design/">
                    Atlassian Design System
                  </ExternalLink>{' '}
                  (Atlassian)
                </Item>
                <Item>
                  <ExternalLink href="https://polaris.shopify.com/">
                    Polaris
                  </ExternalLink>{' '}
                  (Shopify)
                </Item>
                <Item>
                  <ExternalLink href="https://ant.design/">
                    Ant Design
                  </ExternalLink>{' '}
                  (Ant Group)
                </Item>
              </List>
              <Paragraph>
                Given the predominance of Android-based devices in Spain and the
                broad familiarity with many of Google’s services, along with the
                availability of an open-source implementation of components in{' '}
                <ExternalLink href="https://mui.com/">MUI</ExternalLink>{' '}
                (formerly Material UI),{' '}
                <b>
                  Material Design was selected to build the e-government design
                  system
                </b>
                .
              </Paragraph>
              <Paragraph>
                The rest of the requirements were to be implemented on top of
                the design system using agile methodologies and breaking the
                project into 3 milestones progressively enhancing the solution:
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="phases"
                overflow
                alt="Brief description of the three milestones"
              />

              <Paragraph>
                Based on past experience, two other decisions were made to
                speed-up the process:
              </Paragraph>
              <List>
                <Item>
                  Relying on a <b>mobile-first</b> approach where MVP designs
                  are fleshed out first on a mobile-sized display and later
                  enriched for larger displays with complimentary features or
                  information
                </Item>
                <Item>
                  Adopting a <b>grayscale palette</b> to ensure any element
                  works visually without relying on color frist
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="color"
                overflow
                alt="Illustrations with different degrees of color applied"
                description="Use of color"
              />

              <Image
                edges={data.designs.edges}
                name="original"
                overflow
                alt="Original UI component from MUI"
                description="Unstyled UI component from the framework"
              />

              <Image
                edges={data.designs.edges}
                name="components"
                overflow
                alt="UI component with different degrees of color applied"
                description="UI component with modifications and progressively branded"
              />

              <Title>Testing with target organizations</Title>
              <Paragraph>
                The first phase of the e-government design system has already
                been implemented on a pilot software product, here applied to
                selected city councils expressly for this case study:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="badalona"
                overflow
                alt="Design System applied to Ajuntament de Badalona"
                description="Ajuntament de Badalona"
              />

              <Image
                edges={data.designs.edges}
                name="barcelona"
                overflow
                alt="Design System applied to Ajuntament de Barcelona"
                description="Ajuntament de Barcelona"
              />

              <Image
                edges={data.designs.edges}
                name="london"
                overflow
                alt="Design System applied to Mayor of London"
                description="Mayor of London"
              />

              <Image
                edges={data.designs.edges}
                name="berlin"
                overflow
                alt="Design System applied to City of Berlin"
                description="City of Berlin"
              />

              <Image
                edges={data.designs.edges}
                name="wellington"
                overflow
                alt="Design System applied to Wellington City Council"
                description="Wellington City Council"
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
